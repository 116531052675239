import { useEffect, useState } from "react";
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material";
import Countdown from 'react-countdown';
import { useParams } from "react-router-dom";
import { TryCatch } from "utils";
import { getVendorCampaigns,removeVendorFromCampaign } from "services/vendor/VendorService";

export default function VendorCampaignTabs() {
    const [campaigns, setCampaigns] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { id } = useParams();

    const getAllRegisteredCampaign = async () => {
        TryCatch(async () => {
            const res = await getVendorCampaigns(id);
            setCampaigns(res?.data)
        });
    }
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        return completed ? <span style={{ color: 'red' }}>Registration Closed</span> : <span>{days}d : {hours}h : {minutes}m : {seconds}s</span>;
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const removeCampaign = async (campaign_id) => {
        TryCatch(async () => {
            await removeVendorFromCampaign(campaign_id, id);
            getAllRegisteredCampaign();
        });
    };

    useEffect(() => {
        getAllRegisteredCampaign();
    }, [])

    return (
        <>
            <Paper sx={{ overflowX: "auto" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Period</TableCell>
                                <TableCell>Registration Ends</TableCell>
                                <TableCell>Seller</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaigns.length > 0 && campaigns
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((campaign, index) => {
                                    return (
                                        <>
                                            <TableRow
                                                key={index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {campaign.title}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.campaign_start_time?.slice(0, 10)}
                                                    <br /> -<br></br>
                                                    {campaign.campaign_end_time?.slice(0, 10)}
                                                </TableCell>
                                                <TableCell>
                                                    {campaign.registration_end_time?.slice(0, 10)}<br></br>
                                                    <span style={{ color: 'blue' }}>
                                                        <Countdown date={new Date(campaign.registration_end_time)}
                                                            renderer={renderer} />
                                                    </span>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {campaign?.vendors}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        new Date().getTime() < new Date(campaign?.campaign_end_time)?.getTime() ?
                                                            <span
                                                                className="text-danger"
                                                                style={{ cursor: 'pointer' }}
                                                                title="Remove Vendor"
                                                                onClick={() => removeCampaign(campaign?._id)}
                                                            >
                                                                Remove
                                                            </span> : null
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={campaigns?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}