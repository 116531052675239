import { Box } from '@mui/material'
import { AccountStatement } from 'components/vendor/finance'
import React from 'react'

const AccountStatements = () => {
  return (
    <Box sx={{ paddingX: 1 }}>
      <AccountStatement />
    </Box>
  )
}

export default AccountStatements