import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";

const Header = ({ user }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      {
        user ? <AppBar position="static" sx={{ zIndex: '1' }} color="">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Link to="/">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#2E4763"
                >
                  <span style={{ fontWeight: "700" }}>ADMIN</span>
                </Typography>
              </Link>

              <Box sx={{ marginLeft: "auto" }}>
                <Tooltip title="SETTING">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0, border: 2, borderColor: "primary.light" }}
                  >
                    <Avatar alt="Balushai" src="images/balushai.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">
                      <NavLink
                        className={({ isActive }) => (isActive ? "text-success" : "")}
                        to="/log-out"
                      >
                        Logout
                      </NavLink>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar> : null
      }
    </>
  )
};
export default Header;