import { Box, Button, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import "./statement.css"
import { useEffect, useState } from "react";

function Statement({ paid, ctn, statement, paidHandle }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [totalCommission, setTotalCommission] = useState(0);
    const [totalDelivery_fee, setTotalDelivery_fee] = useState(0);
    const [totalVat, setTotalVat] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPenalties, setTotalPenalties] = useState(0);
    const [totalItemsPrice, setTotalItemsPrice] = useState(0);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const AllMoneyCalculation = () => {
        let total_items_price = 0, delivery_fee = 0, commission = 0, vat = 0, total_price = 0;
        statement && statement?.forEach(singleStatement => {
            singleStatement?.products.length && singleStatement?.products?.forEach((product) => {
                commission += ((product?.total_price - product?.shipment_fee) * product?.product_id?.category[product?.product_id?.category?.length - 1]?._id?.commission_rate) / 100;
                vat += ((product?.total_price - product?.shipment_fee) * product?.product_id?.category[product?.product_id?.category?.length - 1]?._id?.vat) / 100;
                delivery_fee += product?.shipment_fee;
                total_price += (product?.total_price - product?.shipment_fee);
            })
        })
        total_items_price = (total_price + delivery_fee) - (commission + vat);
        setTotalCommission(commission);
        setTotalDelivery_fee(delivery_fee);
        setTotalVat(vat);
        setTotalPrice(total_price);
        setTotalPenalties(paid?.penalties || 0);
        setTotalItemsPrice(total_items_price);
    }
    useEffect(() => {
        AllMoneyCalculation();
    }, [statement])

    if (ctn) {
        return (
            <div className="pt-3">
                <div className="statement-center">
                    <div className="card m-4">
                        <div className="paidMsg" style={paid?.status ? { backgroundColor: '#30a034' } : { backgroundColor: '#2E4763' }}>
                            {paid?.status ? <>Paid</> : <>Un Paid</>}
                        </div>
                        <div className="container p-5">
                            <div className="row">
                                <div className="d-flex bd-highlight mb-3">
                                    <div className="p-2 bd-highlight">
                                        <strong>
                                            Orders
                                        </strong>
                                    </div>
                                    <div className="ps-5 pe-2">
                                        <div className="d-flex ps-3 mb-3">
                                            <div className="d-flex flex-column">
                                                <div className="mt-1">
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? 'long-menu' : undefined}
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                        sx={{ fontSize: '17px', color: 'black' }}
                                                    >
                                                        <u>Item Charges</u>
                                                    </IconButton>
                                                    <Menu
                                                        id="long-menu"
                                                        MenuListProps={{
                                                            'aria-labelledby': 'long-button',
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        {
                                                            statement?.map((order, index) => {
                                                                return (
                                                                    order?.products?.length ?
                                                                        <MenuItem key={index} onClick={handleClose}>
                                                                            <Link to={{
                                                                                pathname: `/finance/order-overview`
                                                                            }}>{order?._id}</Link>
                                                                        </MenuItem>
                                                                        : null
                                                                )
                                                            })
                                                        }
                                                    </Menu>

                                                </div>
                                                <div className="p-2">
                                                    Other Credit
                                                </div>
                                                <div className="p-2">
                                                    Commission
                                                </div>
                                                <div className="p-2" title={paid?.penalties ? paid?.penalties_reason : ''}>
                                                    Penalties
                                                </div>
                                                <div className="p-2 text-black">VAT</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-auto p-2">
                                        <div className="d-flex ps-4 mb-3 flex-end">
                                            <div className="d-flex flex-column flex-end mb-3">
                                                <div className="pt-2 pb-2 text-end">
                                                    {totalPrice}
                                                </div>
                                                <div className="pt-2 pb-2 text-end">
                                                    {totalDelivery_fee}
                                                </div>
                                                <div className="pt-2 pb-2  text-end">
                                                    -{totalCommission}
                                                </div>
                                                <div className="pt-2 pb-2  text-end">
                                                    -{totalPenalties}
                                                </div>
                                                <div className="pt-2  text-end">
                                                    -{totalVat}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ps-5 pt-0 justify-content-end">
                                    <hr className="line"></hr>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex bd-highlight mb-3">
                                    <div className="p-2 bd-highlight">
                                        <strong>
                                            Refunds
                                        </strong>
                                    </div>
                                    <div className="ps-5 pe-2">
                                        <div className="d-flex ps-3 mb-3">
                                            <div className="d-flex flex-column">
                                                <div className="p-2 mt-1">
                                                    <u>Item Charges</u>
                                                </div>
                                                <div className="p-2">
                                                    Other Credit
                                                </div>
                                                <div className="p-2">
                                                    Commission
                                                </div>
                                                <div className="p-2">
                                                    Penalties
                                                </div>
                                                <div className="p-2 text-black">VAT</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-auto p-2">
                                        <div className="d-flex ps-4 mb-3 flex-end">
                                            <div className="d-flex flex-column flex-end mb-3">
                                                <div className="pt-2 ps-2 pb-2 pe-0 text-end">
                                                    0
                                                </div>
                                                <div className="pt-2 ps-2 pb-2 pe-0 text-end">
                                                    0
                                                </div>
                                                <div className="pt-2 ps-2 pb-2  text-end">
                                                    -0
                                                </div>
                                                <div className="pt-2 ps-2 pb-2  text-end">
                                                    -0
                                                </div>
                                                <div className="pt-2 ps-2  text-end">
                                                    -0
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider>Total</Divider>
                            <div className="row">
                                <div className="d-flex bd-highlight mb-3">
                                    <div className="p-2 bd-highlight">
                                        <strong>
                                            Balance
                                        </strong>
                                    </div>
                                    <div className="ps-5 pe-2 bd-highlight">
                                        <div className="d-flex ps-4 bd-highlight mb-3">
                                            <div className="d-flex flex-column bd-highlight mb-3">
                                                <div className="p-2 bd-highlight">Total balance</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-auto p-2 bd-highlight">
                                        <div className="d-flex ps-4 bd-highlight mb-3 flex-end">
                                            <div className="d-flex flex-column flex-end bd-highlight mb-3">
                                                <div className="ps-2 pb-2 pe-0 bd-highlight text-end">
                                                    {totalItemsPrice} BDT
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider>Payout</Divider>
                            <div className="row">
                                <div className="d-flex bd-highlight mb-3">
                                    <div className="p-2 bd-highlight">
                                        <strong>Payout</strong>
                                    </div>
                                    <div className="ps-5 pe-2 bd-highlight">
                                        <div className="d-flex ps-4 bd-highlight mb-3">
                                            <div className="d-flex flex-column bd-highlight mb-3">
                                                {
                                                    paid?.payment_date ?
                                                        <div className="p-2 bd-highlight">
                                                            Payment was paid on<br />
                                                            {paid?.payment_date}
                                                        </div> :
                                                        <div className="p-2 bd-highlight">
                                                            Estimated date of payment<br />
                                                            {paid?.end_date?.slice(0, 10)} to 15 Days
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-auto p-2 bd-highlight">
                                        <div className="d-flex ps-4 bd-highlight mb-3 flex-end">
                                            <div className="d-flex flex-column flex-end bd-highlight mb-3">
                                                <div className="ps-2 pb-2 pe-0 bd-highlight text-end">
                                                    <strong>{totalItemsPrice} BDT</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    paid.status ? null : <Box
                        sx={{
                            backgroundColor: "white",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span></span>
                        <Button onClick={paidHandle} color="success" variant="contained">
                            Paid
                        </Button>
                    </Box>
                }
            </div>
        );
    } else {
        return <center style={{ marginTop: '100px' }}>No Statements Found</center>
    }
}
export default Statement;