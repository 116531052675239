import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { CreatedDateCheck, getStatement, paidStatement, setRowsDatabase } from 'services/finance/FinanceService';
import { ErrorToast, TryCatch } from 'utils';
import { useParams } from "react-router-dom";
import Statement from './Statement';
import Loader from 'layouts/loader/loader';
import Swal from 'sweetalert2';
const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
const ONE_DAYS = 1 * 24 * 60 * 60 * 1000;

function AccountStatement() {
    const [status, setStatus] = useState('');
    const [statement, setStatement] = useState(null);
    const [loader, setLoader] = useState(false)
    const [ctn, setCtn] = useState(0);
    const [rows, setRows] = useState([]);
    const [paid, setPaid] = useState(false);
    const { id } = useParams();
    const handleChange = (event) => {
        TryCatch(async () => {
            setStatus(event.target.value);
            const res = await getStatement(id, event.target.value);
            let productCtn = 0;
            res?.data?.statement?.forEach(order => {
                if (order.products?.length) productCtn++
            })
            setCtn(productCtn);
            setStatement(res?.data?.statement)
            setPaid(res?.data?.finance)
        })
    };
    const checkSellerAccountCreatedDate = () => {
        TryCatch(async () => {
            const res = await CreatedDateCheck(id);
            let date = new Date(res?.data?.createdAt);
            const diffTime = Math.abs(new Date() - new Date(date));
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const cnt = Math.floor(diffDays / 7);
            let tempRow = [];
            for (let i = 0; i < cnt; i++) {
                const tmpDate = date?.toISOString()?.slice(0, 10);
                date = new Date(date?.getTime() + (SEVEN_DAYS));
                const tmp = {
                    value: `${tmpDate}/${date?.toISOString()?.slice(0, 10)}`,
                    label: `${tmpDate} - ${date?.toISOString()?.slice(0, 10)}`
                }
                tempRow.push(tmp);
                date = new Date(date?.getTime() + (ONE_DAYS));
            }
            await setRowsDatabase(id, tempRow);
            setRows(tempRow?.reverse());
        });
    }
    const paidHandle = async () => {
        setLoader(true);
        try {
            await paidStatement(id, paid);
            await Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Payout Succeed',
                showConfirmButton: false,
                timer: 1500
            })
            window.location.href = `/#/vendor/list`
        } catch (error) {
            ErrorToast('Something went wrong')
        } finally {
            setLoader(false);
        }
    }
    useEffect(() => {
        checkSellerAccountCreatedDate();
    }, [])

    return (
        <>
            {loader ? <Loader /> :
                <>
                    <Box sx={{ maxWidth: 240, marginBottom: '15px' }}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Date"
                                onChange={handleChange}
                            >
                                {
                                    rows?.length > 0 && rows?.map((row, index) => {
                                        return (
                                            <MenuItem key={index} value={row?.value}>{row?.label}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                    </Box>
                    <Statement paid={paid} ctn={ctn} statement={statement} paidHandle={paidHandle} />
                </>
            }
        </>
    )
}

export default AccountStatement 